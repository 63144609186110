"use client"
import { useIsLogin } from "@/hooks/auth/use-is-login"
import { useDatalayer } from "@/hooks/use-datalayer"
import { usePathname, useSearchParams } from "next/navigation"
import PopupAction from "../shared/popup-action/popup-action"
import ButtonAction from "../shared/button-action/button-action"
import { useReqUrl } from "@/hooks/auth/use-req-url"
import { useContext } from "react"
import { Login } from "@/hooks/auth/use-login-popup"
import { Spinner } from "../shared/spinner"

const PopupLogin = () => {
  const pathname = usePathname()
  const searchParams = useSearchParams()
  const { isOpen, close } = useContext(Login)

  const articleDetailUb =
    pathname.includes("/article") || pathname.includes("/video")

  const { execute: login } = useReqUrl({
    redirectUrl: articleDetailUb
      ? "/br"
      : `${pathname}?${[...searchParams.entries()].map((param) => param.join("=")).join("&")}`,
  })
  const { execute: register } = useReqUrl({
    page: "register",
    redirectUrl: articleDetailUb
      ? "/br"
      : `${pathname}?${[...searchParams.entries()].map((param) => param.join("=")).join("&")}`,
  })

  const datalayer = useDatalayer()
  const { isLogin, isLoading } = useIsLogin()

  const handleDatalayer = (action) => {
    const name = action === "login" ? "masuk" : "daftar"
    datalayer.push({
      event: `general_event`,
      event_name: `click_${action}_button`,
      feature: action,
      button_name: name,
      page_path: pathname,
      campaign_name: "all",
    })
  }

  return (
    <PopupAction
      className={"lg:max-w-[460px] !bg-bg-3"}
      isShowing={isOpen && !isLogin}
      href="#"
      showCloseBtn={false}
      zIndex="z-20"
    >
      {(searchParams.get("to") === "allaccess" ||
        searchParams.get("to") === "allaccess-register") &&
      !isLoading &&
      !isLogin ? (
        <Spinner />
      ) : (
        <div className="bg-bg-3">
          <div className="flex items-center relative mb-[12px]">
            <h5 className="text-text-2 font-bold font-font-family-7 text-[24px] leading-[32px] capitalize">
              Selangkah Lagi Nih
            </h5>
            <button onClick={close} className="absolute right-0">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M5.12079 5.0928C5.51067 4.70215 6.14279 4.70215 6.53268 5.0928L18.9074 17.4921C19.2973 17.8828 19.2973 18.5162 18.9074 18.9068C18.5175 19.2975 17.8854 19.2975 17.4955 18.9068L5.12079 6.50749C4.73091 6.11684 4.73091 5.48346 5.12079 5.0928Z"
                  className="fill-text-1"
                />
                <path
                  d="M5.09222 18.9068C4.70233 18.5162 4.70233 17.8828 5.09222 17.4921L17.4669 5.0928C17.8568 4.70214 18.4889 4.70214 18.8788 5.0928C19.2687 5.48345 19.2687 6.11683 18.8788 6.50749L6.5041 18.9068C6.11422 19.2975 5.4821 19.2975 5.09222 18.9068Z"
                  className="fill-text-1"
                />
              </svg>
            </button>
          </div>
          <p className="text-text-2 mb-[12px] text-left text-[13px] leading-[22px]">
            Yuk selesaikan langkah terakhir supaya bisa akses konten-konten
            eksklusif!
          </p>
          <div className="flex items center">
            <ButtonAction
              onClick={() => {
                handleDatalayer("login")
                login()
              }}
              fullwidth
              intent="secondary"
              className="mr-[8px]"
            >
              Masuk
            </ButtonAction>
            <ButtonAction
              onClick={() => {
                handleDatalayer("registration")
                register()
              }}
              fullwidth
              intent="primary"
            >
              Daftar
            </ButtonAction>
          </div>
        </div>
      )}
    </PopupAction>
  )
}

export default PopupLogin
