/* eslint-disable react-hooks/exhaustive-deps */
"use client"
import { ToastComponent } from "@/components/toast"
import { useEffect, useState } from "react"
import { useIsLogin } from "@/hooks/auth/use-is-login"
import { usePathname, useSearchParams } from "next/navigation"
import { consoleHelper, networkFlow, statusConts } from "@/helper/utils"
import { ClientApiList } from "@/lib/api/client-service"

export const ExtraPointSnackbar = () => {
  const searchParams = useSearchParams()
  const pathname = usePathname()
  const [point, setPoint] = useState(false)
  const [loadingCRM, setLoadingCRM] = useState(true)
  const { isLogin, isLoading } = useIsLogin()

  const fetchPoint = async () => {
    try {
      setLoadingCRM(true)
      const payload = {
        utm:
          process.env.NEXT_PUBLIC_META_URL +
          pathname +
          "?" +
          searchParams.toString().split("&").filter(param => param.includes('utm')).join('&')
      }
      const { data } = await ClientApiList.postCRMExtraPoint(payload)
      if (data?.data?.point) setPoint(data?.data?.point)
      setLoadingCRM(false)
    } catch (error) {
      setLoadingCRM(false)
      consoleHelper(statusConts.error, error, networkFlow.received)
    }
  }

  useEffect(() => {
    if (
      isLogin &&
      !isLoading &&
      pathname != "allaccess-loading" &&
      searchParams.has("utm_source")
    )
      fetchPoint()
    else setLoadingCRM(false)
  }, [isLogin, isLoading, pathname, searchParams])

  const titleBrand =
    process.env.NEXT_PUBLIC_NAME === "djisamsoe"
      ? `Kamu berhasil raih ${point} poin`
      : `Lo Berhasil Dapetin ${point} Poin`

  const subBrand =
    process.env.NEXT_PUBLIC_NAME === "djisamsoe"
      ? `Lanjut eksplor dan kumpulkan lebih banyak poin!`
      : `Eksplor lebih banyak tantangan buat dapet lebih banyak poin`

  return (
    <>
      {!loadingCRM ? (
        <ToastComponent
          onClick={() => {
            setPoint(false)
          }}
          title={titleBrand}
          desc={subBrand}
          isOpen={point}
        />
      ) : null}
    </>
  )
}
