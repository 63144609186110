"use client"
import Script from "next/script"

const OneTrustScript = () => {
  return (
    <>
      <Script
        strategy="afterInteractive"
        id="onetrust-script"
        src={process.env.NEXT_PUBLIC_OPTANON_URL}
        data-document-language="true"
        type="text/javascript"
        charset="UTF-8"
        data-domain-script={process.env.NEXT_PUBLIC_OPTANON_DATA}
      />
      <Script
        strategy="afterInteractive"
        type="text/javascript"
        id="optanon-script"
        dangerouslySetInnerHTML={{
          __html: `
				function OptanonWrapper() { }
				`,
        }}
      />
    </>
  )
}

export default OneTrustScript
