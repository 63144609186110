"use client"

import { LoginPopupProvider } from "@/hooks/auth/use-login-popup"
import { PrevPathProvider } from "@/hooks/use-prev-path-ctx"
import RCProvider from "@/lib/react-query/provider"

export function Providers({ children }) {
  return (
    <RCProvider>
      <PrevPathProvider>
        <LoginPopupProvider>{children}</LoginPopupProvider>
      </PrevPathProvider>
    </RCProvider>
  )
}
