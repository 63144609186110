"use client"
import { ToastComponent } from "@/components/toast"
import { useDatalayer } from "@/hooks/use-datalayer"
// import { useEffect, useState } from "react"
import { useIsLogin } from "@/hooks/auth/use-is-login"
import {
  usePathname,
  // useRouter, useSearchParams
} from "next/navigation"
import { ClientStorage } from "ClientStorage"
import { useEffect, useRef, useState } from "react"
// import UseQueryParams from "@/hooks/use-query-params"

export const LoginSnackbar = () => {
  // const QUERYPARAM = "point"
  // const { handlePushQuery } = UseQueryParams()
  // const { replace } = useRouter()
  // const searchParams = useSearchParams()
  // const [point, setPoint] = useState(false)
  const localStorage = new ClientStorage("localStorage")
  const pathname = usePathname()
  const getPoint = localStorage.get("point-login")
  const refPoint = useRef(localStorage.get("point-login"))
  const datalayer = useDatalayer()
  const { isLogin, isLoading } = useIsLogin()
  const [isShowing, setIsShowing] = useState(false)

  useEffect(() => {
    setIsShowing(
      isLogin && !isLoading && pathname != "allaccess-loading" && getPoint
    )
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, isLogin, pathname])

  // useEffect(() => {
  //   if (isLogin && !isLoading && pathname != "allaccess-loading" && getPoint) {
  //     setPoint(getPoint)
  //     localStorage.remove("point-login")
  //     handlePushQuery(false, [QUERYPARAM, true])
  //   }
  //   /* eslint-disable-next-line react-hooks/exhaustive-deps */
  // }, [isLogin, isLoading, pathname, getPoint])

  const titleBrand =
    process.env.NEXT_PUBLIC_NAME === "djisamsoe"
      ? `Kamu berhasil raih ${refPoint.current} poin`
      : `Lo Berhasil Dapetin ${refPoint.current} Poin`

  const subBrand =
    process.env.NEXT_PUBLIC_NAME === "djisamsoe"
      ? `Lanjut eksplor dan kumpulkan lebih banyak poin!`
      : `Eksplor lebih banyak tantangan buat dapet lebih banyak poin`

  return (
    <ToastComponent
      onClick={() => {
        setIsShowing(false)
      }}
      title={titleBrand}
      desc={subBrand}
      onShow={() => {
        datalayer.push({
          event: "general_event",
          event_name: `page_reached_finish_on_daily_login`,
          feature: "daily login",
          engagement_type: "daily login",
          engagement_name: "daily login",
          campaign_name: "all", // e.g all
          event_label: `daily login - ${titleBrand}`,
          character_result: titleBrand,
          level: 1,
        })
        localStorage.remove("point-login")
      }}
      isOpen={isShowing}
    />
  )
}
