/* eslint-disable react-hooks/exhaustive-deps */
"use client";
import { ToastComponent } from "@/components/toast";
import { useEffect, useState } from "react";
import { useIsLogin } from "@/hooks/auth/use-is-login";
import { usePathname, useSearchParams } from "next/navigation";
import {
  consoleHelper,
  networkFlow,
  snackbarPoint,
  statusConts,
} from "@/helper/utils";
import { ClientApiList } from "@/lib/api/client-service";
import { LoadingOverlay } from "../loading-overlay";

const OfflineEngagementSnackbar = () => {
  const searchParams = useSearchParams();
  const pathname = usePathname();
  const { isLogin, isLoading } = useIsLogin();

  // OE means "Offline Engagement"
  const oeData = searchParams.get("oedata");
  const isOEInspiraksi = searchParams.get("oename") === "inspiraksi";
  const [loadingCRM, setLoadingCRM] = useState(false);
  const [isShowToast, setIsShowToast] = useState(false);
  const [oePoint, setOePoint] = useState(0);

  useEffect(() => {
    const fetchPoint = async () => {
      try {
        setLoadingCRM(true);

        const payload = {
          data: oeData,
          venue: searchParams.get("venue"),
        };

        const { data } =
          await ClientApiList.postOfflineEngagementPoint(payload);
        if (data?.data?.data?.point_get)
          setOePoint(data?.data?.data?.point_get);

        setIsShowToast(true);
        setTimeout(() => {
          setLoadingCRM(false);
        }, 1000);
      } catch (error) {
        setLoadingCRM(false);
        consoleHelper(statusConts.error, error, networkFlow.received);
      }
    };

    if (
      isLogin &&
      !isLoading &&
      pathname != "allaccess-loading" &&
      isOEInspiraksi &&
      oeData
    )
      fetchPoint();
  }, [isLogin, isLoading, pathname, oeData, isOEInspiraksi]);

  return (
    <>
      {!loadingCRM ? (
        <ToastComponent
          onClick={() => {
            setIsShowToast(false);
          }}
          title={snackbarPoint(oePoint)?.title}
          desc={snackbarPoint()?.subtitle}
          isOpen={isShowToast && oePoint}
        />
      ) : (
        <LoadingOverlay visible />
      )}
    </>
  );
};

export default OfflineEngagementSnackbar;
