// In Next.js, this file would be called: app/providers.jsx
"use client"

// Since QueryClientProvider relies on useContext under the hood, we have to put 'use client' on top
import {
  // isServer,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query"
import { useState } from "react"

export default function RCProvider({ children }) {
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            // 1 minute
            staleTime: 60 * 1000,
          },
        },
      })
  )

  return (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  )
}
